<template>
  <div id="app">
    <keep-alive max="1">
       <router-view v-if="this.$route.meta.keepAlive" :key="$route.path"></router-view>
    </keep-alive>
    <router-view v-if="!this.$route.meta.keepAlive"></router-view>
    <!-- <div class="lineCust" @click="urlPost()">
      <img class="tplImg" src="./assets/mode/servePop.png" alt="">
      <p class="tplTit">在线咨询</p>
    </div> -->
    <el-dialog :visible.sync="dialogVisible" class="popMain" :close-on-click-modal="false" :modal="false">
      <iframe :src="url" frameborder="0" width="100%" height="650"></iframe>
      <!-- <div slot="footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div> -->
    </el-dialog>
  </div>
  
</template>

<script>


export default {
  name: 'App',
  data(){
    return {
      isSdkReady:false,
      dialogVisible: false,
      url: null
    }
  },
  created() {
    // (function (w, d, n, a, j) {
    //     w[n] =
    //     w[n] ||
    //     function () {
    //         (w[n].a = w[n].a || []).push(arguments);
    //     };
    //     j = d.createElement("script");
    //     j.async = true;
    //     j.src = "https://qiyukf.com/script/e81a376a0ae5d7748113c448f6fd0fa0.js?hidden=1";
    //     d.body.appendChild(j);
    // })(window, document, 'ysf'); 
    // ysf("onready",()=>{
    //       ysf("config", {
    //         connectYunxin: true,
    //         uid: localStorage.getItem('phone'),
    //         bid: "",
    //       });
    //       ysf("getConversation", function (result) {
    //         console.log("result", result);
    //       });
    //       this.isSdkReady = true;
    //  }); 
   },
  components: {
  
  },
  methods:{
    // urlPost(){
    //   if (this.isSdkReady) {
    //     this.url = ysf('url');
    //     this.dialogVisible = true;
    //   } else {
    //     alert('sdk尚未加载成功，请稍后再试');
    //   }
    // }
  }
}
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  user-select: none;
}
.qrCode {
  width: 150px ;
  height: 150px ;
  border-radius: 5px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  
}
.lineCust {
  position: fixed;
  top: 0;
  top: 55%;
  right: 10px;
  cursor: pointer;
  .tplImg {
    width: 90px;
    height: 80px;
    display: block;
    margin-right: 10px;
  }
  .tplTit {
    margin: 0;
    font-size: 14px;
    margin-right: -15px;
    
  }
  .tplTit::after {
    content: ""; /* 空字符串，用于插入图标 */
    display: block; /* 将伪元素设置为块级元素 */
    margin-left: 10px;
    width: 15px; /* 设置图标的宽度 */
    height: 15px; /* 设置图标的高度 */
    background-image: url("./assets/mode/serveIcon.png"); /* 设置图标的背景图片 */
    background-size: cover; /* 将背景图片设置为覆盖整个元素 */
    position: relative; /* 设置定位方式为相对定位 */
    top: 40%; /* 将图标的顶部与元素中心对齐 */
    transform: translateY(-95%); /* 使用 transform 将图标向上平移一半高度，实现居中效果 */
  }
}
.popMain  .el-dialog{
  position: fixed;
  bottom: 500px;
  left: 50px;
}
.popMain .el-dialog__body {
  padding: 0;
  margin: 0;
}
.popMain .el-dialog {
  width: 600px;
  height: 100px;
}
.popMain .el-dialog__header {
  padding: 0;
}
.popMain .el-dialog__close {
  margin-top: 5px;
  color: #fff !important;
}
</style>
